<template>
  <div class="card card-custom card-stretch gutter-b card-shadowless bg-light">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          {{ $t('Dashboard.Virtualassistantinteractionsthisweek') }}
        </span>
        <span class="text-muted mt-3 font-weight-bold font-size-sm">
          {{ $t('Dashboard.MoreThanXCommands') }} {{ Math.floor(total / 100) * 100 }} {{ $t('Dashboard.CommandsThisWeek') }}
        </span>
      </h3>

      <b-dropdown size="sm" variant="link" toggle-class="topbar-item text-decoration-none" no-caret no-flip right>
        <template v-slot:button-content>
          <div class="card-toolbar" data-toggle="dropdown" data-offset="10px,0px">
            <a href="#" class="btn btn-success font-weight-bolder font-size-sm">
              <span class="svg-icon svg-icon-md svg-icon-white">
                <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Add-user.svg-->
                <inline-svg src="media/svg/icons/Communication/Add-user.svg" />
                <!--end::Svg Icon-->
              </span>
              {{ $t('Dashboard.Addvirtualassistant') }}
            </a>
          </div>
        </template>

        <!--begin::Navigation-->
        <div class="navi navi-hover min-w-md-250px py-5">
          <b-dropdown-text tag="div" class="navi-item">
            <a href="#" class="navi-link">
              <div>
                <b-button v-b-modal.modal-1 variant="link text-decoration-none">
                  {{ $t('Dashboard.Suitch') }}
                </b-button>
                <b-modal id="modal-1" title="Suitch">
                  <p class="my-4">{{ $t('Dashboard.Window1') }}!</p>
                </b-modal>
              </div>
            </a>
          </b-dropdown-text>

          <b-dropdown-text tag="div" class="navi-item">
            <a href="#" class="navi-link">
              <div>
                <b-button v-b-modal.modal-2 variant="link text-decoration-none">
                  {{ $t('Dashboard.Siri') }}
                </b-button>
                <b-modal id="modal-2" title="Siri">
                  <p class="my-4">{{ $t('Dashboard.Window2') }}!</p>
                </b-modal>
              </div>
            </a>
          </b-dropdown-text>

          <b-dropdown-text tag="div" class="navi-item">
            <a href="#" class="navi-link">
              <div>
                <b-button v-b-modal.modal-3 variant="link text-decoration-none">
                  {{ $t('Dashboard.GoogleHome') }}
                </b-button>

              </div>
            </a>
          </b-dropdown-text>

          <b-dropdown-text tag="div" class="navi-item">
            <a href="#" class="navi-link">
              <div>
                <b-button v-b-modal.modal-4 variant="link text-decoration-none">
                  {{ $t('Dashboard.Alexa') }}
                </b-button>

              </div>
            </a>
          </b-dropdown-text>

          <b-dropdown-text tag="div" class="navi-item">
            <a href="#" class="navi-link">
              <div>
                <b-button v-b-modal.modal-5 variant="link text-decoration-none">
                  {{ $t('Dashboard.Nest') }}
                </b-button>
              </div>
            </a>
          </b-dropdown-text>

          <b-dropdown-text tag="div" class="navi-item">
            <a href="#" class="navi-link">
              <div>
                <b-button v-b-modal.modal-6 variant="link text-decoration-none">
                  {{ $t('Dashboard.Acurite') }}
                </b-button>
              </div>
            </a>
          </b-dropdown-text>
        </div>
        <!--end::Navigation-->
      </b-dropdown>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body py-0">
      <!--begin::Table-->
      <div class="d-flex justify-content-center mb-3" v-if="list == null">
        <b-spinner variant="primary">
        </b-spinner>
        <br>
        <br>
        <br>
      </div>
      <div class="table-responsive" v-if="list != null">
        <table class="table table-head-custom table-vertical-center" id="kt_advance_table_widget_1">
          <thead>
            <tr class="text-left">
              <th class="pl-0" style="width: 20px">
                <label class="checkbox checkbox-lg checkbox-single">
                  <input type="checkbox" @input="setCheck($event.target.checked)" />
                  <span></span>
                </label>
              </th>
              <th class="pr-0" style="width: 50px">{{ $t('Dashboard.Virtualassistant') }}</th>
              <th style="min-width: 200px"></th>
              <th style="min-width: 150px">{{ $t('Dashboard.provider') }}</th>
              <th style="min-width: 150px">{{ $t('Dashboard.commandsreceived') }}</th>
              <th class="pr-0 text-right" style="min-width: 150px">{{ $t('Dashboard.action') }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in list">
              <tr v-bind:key="i">
                <td class="pl-0">
                  <label class="checkbox checkbox-lg checkbox-single">
                    <input type="checkbox" value="1" :checked="checked" />
                    <span></span>
                  </label>
                </td>
                <td class="pr-0">
                  <div class="symbol symbol-50 symbol-light mt-1">
                    <span class="symbol-label">
                      <img :src="getIconPath(item.assistant)" class="h-75 align-self-end" alt="" />
                    </span>
                  </div>
                </td>
                <td class="pl-0">
                  <a
                    :href="'#/reports/' + item.assistant"
                    class="
                      text-dark-75
                      font-weight-bolder
                      text-hover-primary
                      mb-1
                      font-size-lg
                    "
                  >{{ item.assistant | capitalize }}</a>

                  <span class="text-muted font-weight-bold text-muted d-block">{{ item.none }}</span>
                </td>
                <td>
                  <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ item.provider | capitalize
                  }}</span>
                  <span class="text-muted font-weight-bold">{{
                    item.text4
                  }}</span>
                </td>
                <td>
                  <div class="d-flex flex-column w-100 mr-2">
                    <div class="
                        d-flex
                        align-items-center
                        justify-content-between
                        mb-2
                      ">
                      <span class="text-muted mr-2 font-size-sm font-weight-bold">{{ item.text5 }}</span>
                      <span class="text-muted font-size-sm font-weight-bold">{{ item.count }}
                        {{ $t('Dashboard.received') }}</span>
                    </div>
                    <div class="progress progress-xs w-100">
                      <div class="progress-bar" role="progressbar" :style="{ width: porcentage(item.count) }"
                        v-bind:class="`bg-primary`" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </td>
                <td class="pr-0 text-right">
                  <a href="#" class="btn btn-icon btn-light btn-hover-primary btn-sm">
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon | path:assets/media/svg/icons/General/Settings-1.svg-->
                      <inline-svg src="media/svg/icons/General/Settings-1.svg" />
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                  <a href="#" class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                      <inline-svg src="media/svg/icons/Communication/Write.svg" />
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                  <a href="#" class="btn btn-icon btn-light btn-hover-primary btn-sm">
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                      <inline-svg src="media/svg/icons/General/Trash.svg" />
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
    <b-modal id="modal-3">
      <b-jumbotron header="Google Assistant" lead="Configuration instructions">
        <p>
          Google home/assistant configuration is pretty straight forward, follow these instructions to link your Google
          account to Suitch
        </p>
        <p>
          1. Open the Google Home APP in your phone
          and log into your account.
        </p>
        <p>
          2. Click on the + menu and select "Set up a device" and then select "Works with Google"
        </p>
        <p>
          3. When prompted, search for the "Suitch" skill, you will be taken to the Suitch login section to validate
          linking
        </p>
        <p>
          4. Once validated, Google and your Suitch account will be linked and devices can talk each other.
        </p>
        <p>
          You will start receiving events from Google Home now, which you can use in
          the "Chain reaction" module, control by voice and setup schedule from the Google Assistant app. If you wish to
          unlink your
          account, a button below will appear <i>only</i> when your account is linked.
        </p>
      </b-jumbotron>
    </b-modal>
    <b-modal id="modal-4">
      <b-jumbotron header="Alexa" lead="Configuration instructions">
        <p>
          Alexa configuration is pretty straight forward, follow these instructions to link your Amazon Alexa account to
          Suitch
        </p>
        <p>
          1. Open the Alexa APP in your phone or go to
          <a href="https://alexa.amazon.com">https://alexa.amazon.com</a> and log into your account.
        </p>
        <p>
          2. Click on the "Skills" menu and in the search box, type "Suitch"
        </p>
        <p>
          3. You will be taken to the Suitch login section to validate linking
        </p>
        <p>
          4. Once validated, Alexa and your Suitch account will be linked and devices can talk each other.
        </p>
        <p>
          You will start receiving events from Alexa now, which you can use in
          the "Chain reaction" module, control by voice and setup schedule from the Alexa app. If you wish to unlink your
          account, a button below will appear <i>only</i> when your account is linked.
        </p>
      </b-jumbotron>
    </b-modal>
    <b-modal id="modal-6">
      <b-jumbotron header="Acurite" lead="Configuration instructions">
        <p>
          Acurite is a weather service, if you have any acurite product and also the wifi bridge
          you probably have a cloud account, follow these instructions for interacting with acurite services:
        </p>
        <p>
          1. Go to
          <a href="https://www.myacurite.com">https://www.myacurite.com</a> and log into your account.
        </p>
        <p>
          2. In the menu "Settings" select the option "Alert rules"
        </p>
        <p>
          3. Create a new rule to notify Suitch, for example a temperature change
        </p>
        <p>
          a) When prompt about Email to send, press "Add a contact Method"
        </p>
        <p>
          b) Your private Suitch reception email is:
          <b>{{ acurite_email }}</b>
        </p>
        <p>
          4. Save the newly created Alert, whenever Acurite detects matching information it will notify Suitch as well
        </p>
        <p>
          You will start receiving events from Accurite now, which you can use in
          the "Chain reaction" module
        </p>
      </b-jumbotron>
    </b-modal>
    <b-modal id="modal-5" @ok="updateNestCode">
      <b-jumbotron header="Nest" lead="Configuration instructions">
        <p>
          In order to plug Nest into Suitch reports and engines, you will need
          to follow the next steps:
        </p>
        <p>
          1. Create a project in the next URL
          <a
            href="https://console.nest.google.com/device-access/project-list">https://console.nest.google.com/device-access/project-list</a>
          Enable Pub/Sub topic and copy the full name <b>projects/xxxx/topics/xxxx</b>
        </p>
        <p>
          2. Continue on
          <a href="https://console.cloud.google.com">https://console.cloud.google.com</a>
          and also create a new project in there, make sure your project has the Smart Device Management API enabled or
          SDM
        </p>
        <p>
          3.Follow the instructions from <a href="https://developers.google.com/nest/device-access/authorize">Google
            Device Access</a> to activate the registration of events
        </p>
        <p>
          4. Now go to
          <a
            href="https://console.cloud.google.com/cloudpubsub/subscription">https://console.cloud.google.com/cloudpubsub/subscription</a>
          and select "Create subscription", make sure to maintain the same Google project selected
        </p>
        <p>
          a) Name your subscription in "Subscription id" with something
          meaningful like "myhouse"
        </p>
        <p>
          b) Paste the Topic that you copy on step 1 when requested
        </p>
        <p>
          c) In the "Delivery type" select "Push" and copy this endpoint
          <b>https://www.suitch.network/devices/pubsub.json</b>
        </p>

        <p>
          5. Copy the generated Subscription name, that has a format like
          <b>projects/xxxx/subscriptions/xxxx</b>
        </p>
        <p>6. Paste in the text input below the subscription name</p>
        <p>
          You will start receiving events from nest now, which you can use in
          the "Chain reaction" module
        </p>
      </b-jumbotron>
      <div role="group">
        <label for="input-live">Nest PubSub Topic:</label>
        <b-alert show variant="success" v-if="getSafe(() => available_codes.nest) != null">Nest PubSub topic
          active</b-alert>
        <b-alert show variant="danger" v-if="getSafe(() => available_codes.nest) == null">PubSub for Nest not
          configured</b-alert>
        <b-form-input id="input-live" v-model="nest_hash" :state="nestHashValidation"
          aria-describedby="input-live-help input-live-feedback" placeholder="Enter your Nest PubSub id"
          trim></b-form-input>

        <!-- This will only be shown if the preceding input has an invalid state -->
        <b-form-invalid-feedback id="input-live-feedback">
          Enter at least 10 letters
        </b-form-invalid-feedback>

        <!-- This is a form text block (formerly known as help block) -->
        <b-form-text id="input-live-help">Your nest API PubSub id</b-form-text>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { FETCH_ASSISTANTS } from "@/core/services/store/assistants.module";
import { ASSISTANTS } from "@/core/enums/assistant-providers.enum";
import { UPDATE_ASSISTANT_CODE, FETCH_ASSISTANT_CODES } from "@/core/services/store/auth.module";

export default {
  name: "widget-8",
  data() {
    return {
      acurite_email: "",
      nest_hash: "",
      available_codes: {},
      getIconPath(assist_id) {
        if (assist_id == undefined) {
          return ASSISTANTS.SUITCH;
        }
        return ASSISTANTS[assist_id.toUpperCase()];
      },
      total: 0,
      list: null,
      /*[{
        text0: ASSISTANTS.AMAZON,
        text1: "Alexa",
        text2: "",
        text3: "Amazon",
        text4: "Web, UI/UX Design",
        text5: "65%",
        text6: "danger"
      },
      {
        text0: ASSISTANTS.GOOGLE,
        text1: "Home",
        text2: "",
        text3: "Google",
        text4: "Houses & Hotels",
        text5: "83%",
        text6: "success"
      },
      {
        text0: ASSISTANTS.SIRI,
        text1: "Siri",
        text2: "",
        text3: "Apple",
        text4: "Transportation",
        text5: "47%",
        text6: "primary"
      },
      {
        text0: ASSISTANTS.SUITCH,
        text1: "Suitch",
        text2: "",
        text3: "Consultware",
        text4: "Insurance",
        text5: "71%",
        text6: "danger"
      },
      {
        text0: ASSISTANTS.NEST,
        text1: "Nest",
        text2: "",
        text3: "Google",
        text4: "Insurance",
        text5: "71%",
        text6: "danger"
      },
      {
        text0: ASSISTANTS.ACURITE,
        text1: "Acurite",
        text2: "",
        text3: "Acurite",
        text4: "Insurance",
        text5: "71%",
        text6: "danger"
      }]*/

      checked: false,
    };
  },
  components: {},
  computed: {
    nestHashValidation() {
      return this.nest_hash.length > 10 ? true : false;
    },
  },
  methods: {
    getSafe,
    updateNestCode() {
      this.$store.dispatch(UPDATE_ASSISTANT_CODE, { assistant: "nest", nest_code: this.nest_hash }).then((result) => {
        console.log(result);
      })
    },
    setCheck(checked) {
      this.checked = checked;
    },
    porcentage(totalCommands) {
      return String((totalCommands * 100) / this.total) + "%";
    },
  },
  mounted() {
    if (localStorage.getItem("user") == null) {
      localStorage.setItem("user", JSON.stringify({ user_id: "empty" }));
    }
    this.acurite_email = JSON.parse(localStorage.getItem("user")).user_id + "-acurite@cloudmailin.net"
    this.$store.dispatch(FETCH_ASSISTANT_CODES).then((codes) => {
      this.available_codes = codes;
    });
    this.$store.dispatch(FETCH_ASSISTANTS).then((assistants) => {
      this.total = assistants
        .map((singleAssistant) => singleAssistant.count)
        .reduce((acc, count) => acc + count);
      this.list = assistants;
    });
  },
};
function getSafe(fn) {
  try { return fn(); }
  catch (e) {
    // 
  }
}
</script>
